import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { //大首页
    path: '/index',
    component: () => import('../views/index/index.vue'),
  },{ //列表页
    path: '/cate/:cid',
    component: () => import('../views/index/index2.vue'),
  },
  { //详情
    path: '/detail/:id', component: () => import('../views/detail/index.vue')
  },
  { //支付
    path: '/pay',  component: () => import('../views/pay/index.vue')
  },
  { //支付状态
    path: '/payResult',  component: () => import('../views/pay/result.vue')
  },
  //重定向：
  { path: '*', redirect: '/index' }
]

const router = new VueRouter({
  routes,
  mode: 'hash',
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
})


export default router
